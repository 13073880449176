const initState = {
    isAuthenticated: false,
    jwt: '',
    username: '',
    fullName: '',
};

const User = (state = initState, action) => {
    switch (action.type) {
        case 'SIGN_IN':
            return { ...state, isAuthenticated: true, jwt: action.payload };
        case 'UPDATE_USERNAME':
            return { ...state, username: action.payload };
        case 'UPDATE_USERINFO':
            return { ...state, ...action.payload };
        case 'SIGN_OUT':
            return {
                username: '',
                fullName: '',
                isAuthenticated: false,
            };
        default:
            return state;
    }
};

export default User;