const initState = {
    data: null,
    dataResult: {},
    checkExam: '',
};

const Exam = (state = initState, action) => {
    switch (action.type) {
        case 'UPDATE_DATA_EXAM':
            return { ...state, data: action.payload };
        case 'UPDATE_EXAM_RESULT':
            return { ...state, dataResult: action.payload };
        case 'CHECK_EXAM':
            return { ...state, checkExam: action.payload };
        default:
            return state;
    }
};

export default Exam;