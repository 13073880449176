import App from 'next/app';
import React from 'react';
import 'react-toastify/scss/main.scss';
import { wrapper } from 'store';
import '../styles/globals.css';
import Router from 'next/router';

import * as ga from '../lib/ga';

// export function reportWebVitals(metric) {
//     console.log(metric);
// }

class ProductXWeb extends App {
    handleRouteChange = (url) => {
        ga.pageview(url);
    }

    componentDidMount() {
        Router.events.on('routeChangeComplete', this.handleRouteChange);
    }

    componentWillUnmount() {
        Router.events.off('routeChangeComplete', this.handleRouteChange);
    }

    render() {
        const { Component, pageProps } = this.props;
        // Workaround for https://github.com/zeit/next.js/issues/8592
        const { err } = this.props;
        const modifiedPageProps = { ...pageProps, err };
        const getLayout = Component.getLayout || (page => page);
        return getLayout(<Component {...modifiedPageProps} />);
    }
}

export default wrapper.withRedux(ProductXWeb);